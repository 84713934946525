<template>
  <section style="margin: 0 24px">
    <section class="form-style">
      <a-form layout="inline">
        <a-form-item label @click="clickFocus">
          <a-input class="form-input" allowClear :maxlength="40" v-model:value="state.searchVal"
            placeholder="请输入文件类型名称/文件类型编号" ref="inputbox" style="width: 500px">
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button class="form-btn" @click="searchList" :loading="searchdLoading">查询</a-button>
        </a-form-item>
      </a-form>
    </section>
    <section class="table-style">
      <div class="table-head">
        <div class="bus-header-left" style="color: black;">文件类型列表</div>
        <div class="bus-header-right">
          <a-button @click="addType" type="primary" class="right-btn" v-btn="'PC_APPROVAL_PROCESS_MENU_ADD'">
            <PlusOutlined /> 添加文件类型
          </a-button>
        </div>
      </div>
      <a-table :columns="documenttypeColumns" :dataSource="state.dataList" @change="handleTableChange"
        :loading="state.tableLoading" :pagination="state.pagination" rowKey="id" ref="tableRef" :scroll="{ x: true }">
        <template #action="{ record }">
          <a @click="viewType(record)" style="color: rgb(24, 144, 255);margin-right: 10px;"
            v-btn="'PC_FINGERPRINT_MENU_DEL'">查看</a>
          <a-dropdown v-if="record.processId === null && record.def == 1">
            <a @click.prevent style="color: rgb(24, 144, 255);">更多
              <DownOutlined style="margin-left: 4px" />
            </a>
            <template #overlay>
              <a-menu class="dropdown-menu">
                <a-menu-item>
                  <a @click="changeType(record)" v-btn="'PC_SEAL_UPDATE'">修改</a>
                </a-menu-item>
                <a-menu-item class="dropdown-item">
                  <a @click=" delType(record)" v-btn="'PC_SEAL_UPDATE'">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <template #typeName="{ text }">
          <div class="text-box">
            <a-tooltip placement="bottomLeft" v-if="text?.length > 22">
              <template #title>
                <span>{{ text }}</span>
              </template>
              {{ text ?? '-' }}
            </a-tooltip>
            <span v-else>{{ text ?? '-' }}</span>
          </div>
        </template>
        <template #typeNumber="{ text }">
          <span :title="text">{{ text ?? '-' }}</span></template>
      </a-table>
    </section>
    <!-- 增加,修改弹窗 -->
    <modal v-if="visible" :visible="visible" :modalVisible="modalVisible" :modaltitle='state.modaltitle'
      :modaltype="modaltype" :jumpData="jumpData" />
  </section>
</template>
<script setup>
import { documenttypeColumns } from './columns'
import { reactive, ref, onMounted, createVNode } from 'vue'
import { getTypeList, deleteTypeList } from '@/apis/businessManage'
import { paginationParams } from '@/utils/constData'
import { setTablePageFooterStyle } from '@/utils/utils'
import { DownOutlined, ExclamationOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue';
import modal from './components/modal.vue'
import { cmsNotice } from '@/utils/utils'
const dog = ref('')
const tableRef = ref()
const visible = ref(false)//弹窗显示与隐藏
const modaltype = ref(null)//弹窗类型 增加add 修改set 查看view
const state = reactive({
  documenttypeColumns,
  searchVal: undefined,
  pagination: {
    ...paginationParams,
  },
  loading: false,
  tableLoading: false,
  dataList: [],
  modaltitle: []
})
//跳转数据
let jumpData = reactive(null)
//搜索列表加载
let searchdLoading = ref(false)
//获取列表数据
const getList = async () => {
  state.tableLoading = true
  const params = {
    search: state.searchVal,
    pageIndex: state.pagination.current,
    pageSize: state.pagination.pageSize
  }
  // state.dataList=
  let res = await getTypeList(params)
  console.log(res);
  state.pagination.total = res.totalItem
  state.pagination.index = res.pageIndex
  state.dataList = res.data
  state.tableLoading = false
}
onMounted(() => {
  getList()
})
const handleTableChange = ({ current, pageSize }, filters,) => {
  state.dataSource = []
  // 页码change
  state.pagination.current = current
  state.currentNum = current
  state.pagination.pageSize = pageSize
  getList()
}
//点击查询
const searchList = () => {
  state.pagination.current = 1
  getList()
}
//新建弹窗
const addType = () => {
  state.modaltitle = '添加文件类型'
  visible.value = true
  modaltype.value = 'add'
}

const changeType = (record) => {
  state.modaltitle = '修改文件类型'
  visible.value = true
  modaltype.value = 'set'
  jumpData = record
}

const viewType = (record) => {
  state.modaltitle = '查看'
  visible.value = true
  modaltype.value = 'view'
  jumpData = record
}
//删除
const delType = (record) => {
  Modal.confirm({
    title: '是否删除文件类型！',
    icon: createVNode(ExclamationOutlined),
    content: '删除文件类型后不能恢复',
    okText: '确定',
    cancelText: '取消',
    centered: true,
    onCancel() { },
    async onOk() {
      deleteTypeList(record.id).then(res => {
        if (res.success) {
          cmsNotice('success', '文件类型删除成功')
          getList()
        }
      })
    }
  })
}
const modalVisible = () => {
  visible.value = false
  getList()
}

// const onFinishFailed = () => {
//   (formState.typeName = ''), (formState.typeNumber = ''), (formState.explain = '')
// }
</script>

<style lang="less" scoped>
.dept-name {
  max-width: 140px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdown-menu {
  width: 94px;
  text-align: center;
}

.text-box {
  max-width: 22em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>